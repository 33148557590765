import SsoIdp from "Figured/Packages/Integrations/Sso/Assets/Modules/models/SsoIdp";
import {http, routes, notify} from "Figured/Assets/Modules";

// helpers

/** @type {RegExp} */
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const stepsAvailable = {
    STEP_EMAIL: "email-only",
    STEP_REGULAR_AUTH: "regular-auth",
    STEP_SSO_AUTH: "sso-auth",
};



export default {

    data() {
        return {
            ssoIdpModel: new SsoIdp(),
            formErrors: {},
            isLoading: false,
            authStep: stepsAvailable.STEP_EMAIL, // initial step
        }
    },
    computed: {
        steps() {
            return stepsAvailable;
        },

    },

    methods: {

        validateEmail(email) {
            return emailRegExp.test(email || "");
        },

        hasErrors(field) {
            return this.formErrors && Object.prototype.hasOwnProperty.call(this.formErrors, field)
                ? this.formErrors[field][0]
                : ""
            ;
        },

        goToSsoAuthStep({ data }) {
            this.ssoIdpModel = new SsoIdp(data);
            this.authStep = this.steps.STEP_SSO_AUTH;
        },

        goToRegularAuthStep() {
            this.authStep = this.steps.STEP_REGULAR_AUTH;
        },

        goToInviteRegister(inviteHash) {
            window.location = routes.get("registration_invite.get", { "inviteHash": inviteHash } )

        },

        goBack() {
            this.ssoIdpModel = new SsoIdp();
            this.formErrors = {};
            this.authStep = this.steps.STEP_EMAIL;
        },

        next(email) {
            if (!this.validateEmail(email)) return;

            this.isLoading = true;

            http.get(routes.get("login.saml", { email } ))
                // if response OK (found -> redirect) i should show the SSO login form type
                .then(this.goToSsoAuthStep)

                .catch(error => {
                    // if 404 (not found) then i should show the passwd keeping in memory the email and re posting
                    if (error.response.status === 404) {
                        this.checkEmailPendingInviteRegistration(email)
                    } else {
                        this.onValidationErrors(error);
                    }
                })

                .finally(() => this.isLoading = false)
            ;
        },

        /**
         * @param {String} email
         * @param {function} regularAuthStrategy
         */
        onSubmit(email, regularAuthStrategy) {
            switch (this.authStep) {

                case this.steps.STEP_EMAIL:
                    this.next(email);
                    break;

                case this.steps.STEP_REGULAR_AUTH:
                    regularAuthStrategy();
                    break;

                case this.steps.STEP_SSO_AUTH:
                // do nothing, it's a redirect by clicking on anchor tag. This case is not being hit atm.
                    break;

                default:
                    throw new Error("invalid state of sign-up form.");
            }
        },

        /**
         * @param {String} email
         * @return boolean
         */
        checkEmailPendingInviteRegistration(email){
            http.get(routes.get("login.unregistered_invite.get",{}, { email } ))
                .then(response => {
                    this.goToInviteRegister(response.data.inviteHash)
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.goToRegularAuthStep()
                    }
                })
                .finally(() => this.isLoading = false)
            ;
        },

        onValidationErrors(error) {
            if (Object.prototype.hasOwnProperty.call(error, "response")) {
                this.formErrors = error.response.data || {};
            } else {
                console.error(error);
            }
        }
    }
}
